.clip-path-group1 {
  align-self: stretch;
  height: 40px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

.clip-path-group-wrapper {
  /* height: 48px;
  width: 80px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl) 0 0;
  box-sizing: border-box;
}

.link-plans8 {
  position: relative;
  line-height: 22.5px;
  font-weight: 600;
}

.link-how7 {
  white-space: nowrap;
}

.link-about7,
.link-contact14,
.link-how7,
.link-partnership7 {
  position: relative;
  line-height: 22.5px;
  font-weight: 600;
}

.link-plans8,
.link-how7,
.link-partnership7,
.link-about7,
.link-contact14 {
  text-decoration: none;
  color: inherit;
  /* Use the default color */
  cursor: pointer;
  /* Show the pointer cursor on hover */
}

.link-plans8:hover,
.link-how7:hover,
.link-partnership7:hover,
.link-about7:hover,
.link-contact14:hover {
  /* Underline the text on hover */
  /* color: #ff9100; */
  color: #ca3770;
  /* Change the color on hover */
}

.link-about7,
.link-contact14 {
  white-space: nowrap;
}

.monty-e-s-i-m {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.heading-how-to-buy {
  height: 32px;
  width: 649px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-4xs);
  box-sizing: border-box;
  max-width: 100%;
}

.en8 {
  position: relative;
  line-height: 21px;
  text-transform: capitalize;
}

.drop-arrowed3f48dfsvg-fill-icon {
  height: 21px;
  width: 10px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.divcard32 {
  align-self: stretch;
  border-radius: var(--br-81xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-athens-gray);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-2xs) var(--padding-4xs) var(--padding-smi);
  gap: 0 4px;
}

.sign-in8 {
  color: inherit;
  text-decoration: none;
  position: relative;
  line-height: 24px;
  white-space: nowrap;
}

.link13 {
  height: 40px;
  border-radius: var(--br-6xl);
  background-color: var(--color-burlywood);
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-6xl) var(--padding-5xs) var(--padding-9xl);
  white-space: nowrap;
  font-size: var(--font-size-base);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}

.divcard-parent,
.link13,
.navigation9 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

/* .divcard-parent {
  height: 40px;
  width: 207px;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0;
  gap: 0 39px;
  font-size: var(--www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
} */

.navigation9.transparent {
  /* background-color: #e2bfb3; */
  background: linear-gradient( #5378ee , #ca3770);
  transition: all .5s;
  box-shadow: none;
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}

.navigation9 {
  align-self: stretch;
  box-shadow: 0 0 25px rgba(42, 106, 152, .1);
  height: 90px;
  background-color: #fff;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 0 var(--padding-6xl) var(--padding-67xl);
  gap: var(--gap-xl);
  top: 0;
  z-index: 10;
  position: sticky;
  width: 100%;
  text-align: center;
  font-size: var(--www-montyesim-com-checkout-1920x1080-default-poppins-semibold-15-size);
  font-family: var(--www-montyesim-com-checkout-1920x1080-default-poppins-light-17);
  color: black;
}

.icon-menu {
  display: none;
  background-color: transparent;
  font-size: 50px;
  cursor: pointer;
}

.side-menu {
  display: none;
}

@media screen and (max-width: 920px) {

  .monty-e-s-i-m,
  .link13 {
    display: none;
  }

  .heading-how-to-buy {
    width: 0;
  }

  .navigation9 {
    padding-left: var(--padding-51xl);
    padding-right: var(--padding-67xl);
    box-sizing: border-box;
  }

  .icon-menu {
    display: block;
    position: absolute;
    right: 6%;
    top: 0.6rem;
    /* padding: 20px 55px 0; */
    background-color: transparent;
  }
  .side-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   font-size: 25px;
    gap: 100px;
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    background-color: #fff;
    z-index: 100;
    color: black;
    transition: all 0.5s;
  }

  .side-menu.active {
    width: 70%;
    right: 0;
  }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 30px;
    cursor: pointer;
    display: none;
  }

}

/* @media screen and (max-width: 900px) {
  .navigation9 {
    /* padding-left: var(--padding-16xl);
    padding-right: var(--padding-24xl); */
    /* box-sizing: border-box; */
 /* }

  .divcard-parent {
     width: 40%; 
  }*/

  /* .icon-menu {
    right: 7rem;
    top: 0.6rem;
  } 
} */

@media screen and (max-width: 500px) {
  
.navigation9 {
    padding-left: 6%;
    /* padding-right: 0; */
  }

  /* .divcard-parent {
    width: 35%;
  } */

  .icon-menu {
    right: 1rem;
    top: 0.8rem;
    background-color: transparent;
  }
  .side-menu {
    font-size: 15px;
    gap: 50px;
  }
  /* .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 30px;
    cursor: pointer;
    display: none;
  } */

  
}