/* #mask{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    display: none;
    transition:  all .3s ease-in-out;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    background: #0000009f;
} */
 
 .content {
    /* z-index: 5; */
    padding: 20px;
    /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* position: absolute; */
    /* left: 5%; */
    transition: all .3s ease-in-out;
    
    /* order: 1; */
}

 .card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
 .header {
    /* width: auto; */
    cursor: pointer;

    /* display: flex;
    flex-wrap: wrap; */
}
@media screen and (max-width: 1200px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
}
 @media screen and (max-width: 900px) {
    
    .card-grid {
        grid-template-columns: repeat(1, 1fr);
        ;
    }
    .divcard{
        font-size: sma;
    }
    .button6{
        width: 100%;
        /* text-align: center; */
    }
 }
  
