.divfooter-section-icon14 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: var(--br-77xl);
}
.link-444,
.phone4 {
  position: relative;
  line-height: 30px;
  font-weight: 300;
}
.link-444 {
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17-size
  );
  line-height: 25.5px;
  white-space: nowrap;
}
.divcol-md-414 {
  width: 563px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-20xl);
  box-sizing: border-box;
  gap: 18px 0;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 450px) {
  .phone4 {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}
