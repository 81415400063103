.divfooter-section-icon12 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: var(--br-77xl);
}
.email6,
.link-supportmontyesimcom3 {
  position: relative;
  line-height: 30px;
  font-weight: 300;
}
.link-supportmontyesimcom3 {
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17-size
  );
  line-height: 25.5px;
  white-space: nowrap;
}
.divcol-md-412,
.email-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px 0;
}
.divcol-md-412 {
  width: 564px;
  padding: 0 0 var(--padding-21xl) var(--padding-12xs);
  box-sizing: border-box;
  gap: 20px 0;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 450px) {
  .email6 {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}
