.europe47a0e084svg-icon {
  height: 55px;
  width: 55px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.euroturktrio {
  position: relative;
  line-height: 24px;
}
.euroturktrio-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-10xs);
}
.europe47a0e084svg-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 10px;
}
.gb1 {
  position: absolute;
  top: 0.1px;
  left: -0.2px;
  line-height: 45px;
  display: flex;
  align-items: center;
  width: 75.2px;
  height: 45px;
}
.arrow-right2472f8ffsvg-icon2 {
  position: absolute;
  top: 13.7px;
  left: 74.6px;
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.button22 {
  height: 45px;
  width: 91px;
  position: relative;
  text-align: right;
  font-size: var(--font-size-11xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}
.frame-parent10 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
}
.divcard-inner,
.separator9 {
  align-self: stretch;
  box-sizing: border-box;
}
.divcard-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs) var(--padding-9xs) var(--padding-11xs);
  max-width: 100%;
}
.separator9 {
  height: 1px;
  position: relative;
  border-top: 1px solid
    var(--www-montyesim-com-checkout-1920x1080-default-black-10);
}
.available-in2,
.validity-10-days {
  position: relative;
  line-height: 18px;
}
.available-in2 {
  line-height: 21px;
}
.trsvg-icon6 {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
  margin-left: -1px;
}
.available-in-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  text-align: right;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--color-darkslategray-100);
}
.div-card-button {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-6xs);
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-gray-300);
}
.supported-countries {
  width: 126.7px;
  border: 0;
  outline: 0;
  font-family: var(--font-tajawal);
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  background-color: transparent;
  height: 21px;
  position: relative;
  line-height: 21px;
  color: var(--color-darkslategray-100);
  text-align: left;
  display: flex;
  align-items: center;
}
.divcol-md-5-icon2 {
  height: 24.6px;
  width: 199.4px;
  position: relative;
}
.divcard21 {
  align-self: stretch;
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-mini) var(--padding-12xs) var(--padding-mini)
    var(--padding-mini);
  gap: var(--gap-xl);
}
.buy-now-2 {
  position: relative;
  line-height: 24px;
}
.button23,
.divcard20 {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
}
.button23 {
  width: 465px;
  border-radius: var(--br-31xl);
  background-color: var(--color-coral);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-2xl) var(--padding-4xs)
    var(--padding-xl);
  white-space: nowrap;
  text-align: center;
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}
.divcard20 {
  width: 513px;
  border-radius: var(--br-xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-base) var(--padding-3xl)
    var(--padding-mid);
  gap: 16px 0;
}
.trsvg-icon7 {
  height: 55px;
  width: 55px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
}
.mutluluk2 {
  position: relative;
  line-height: 24px;
}
.trsvg-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 19px;
}
.gb2 {
  position: relative;
  line-height: 45px;
}
.arrow-right2472f8ffsvg-icon3 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.arrow-right2472f8ffsvg-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
}
.frame-parent12,
.gb-group {
  display: flex;
  flex-direction: row;
}
.gb-group {
  align-items: center;
  justify-content: flex-start;
  text-align: right;
  font-size: var(--font-size-11xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}
.frame-parent12 {
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
}
.frame-wrapper2,
.separator10 {
  align-self: stretch;
  box-sizing: border-box;
}
.frame-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 var(--padding-12xs);
  max-width: 100%;
}
.separator10 {
  height: 1px;
  position: relative;
  border-top: 1px solid
    var(--www-montyesim-com-checkout-1920x1080-default-black-10);
}
.available-in3,
.validity-30-days {
  position: relative;
  line-height: 18px;
}
.available-in3 {
  line-height: 21px;
}
.trsvg-icon8 {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
  margin-left: -1px;
}
.available-in-parent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  text-align: right;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--color-darkslategray-100);
}
.validity-30-days-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-5xs) 0 var(--padding-6xs);
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-gray-300);
}
.supported-country3 {
  width: 117.1px;
  border: 0;
  outline: 0;
  font-family: var(--font-tajawal);
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  background-color: transparent;
  height: 21px;
  position: relative;
  line-height: 21px;
  color: var(--color-darkslategray-100);
  text-align: left;
  display: flex;
  align-items: center;
}
.divcol-md-5-icon3 {
  height: 24.6px;
  width: 199.4px;
  position: relative;
}
.divcard23,
.frame-parent11 {
  align-self: stretch;
  display: flex;
}
.divcard23 {
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-100);
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-mini) var(--padding-12xs) var(--padding-mini)
    var(--padding-mini);
  gap: var(--gap-xl);
}
.frame-parent11 {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px 0;
  max-width: 100%;
}
.buy-now-3 {
  position: relative;
  line-height: 24px;
}
.button24,
.divcard22 {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
}
.button24 {
  width: 465px;
  border-radius: var(--br-31xl);
  background-color: var(--color-coral);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-6xs) var(--padding-4xs);
  white-space: nowrap;
  text-align: center;
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}
.divcard22 {
  width: 513px;
  border-radius: var(--br-xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-base) var(--padding-5xl)
    var(--padding-mid);
  gap: 21px 0;
}
.trsvg-icon9 {
  height: 55px;
  width: 55px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
}
.mutluluk-15-off {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.gb3,
.trsvg-container {
  display: flex;
  align-items: center;
}
.trsvg-container {
  width: 231px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 20px;
}
.gb3 {
  position: absolute;
  top: 0.1px;
  left: -0.2px;
  line-height: 45px;
  width: 86.6px;
  height: 45px;
}
.arrow-right2472f8ffsvg-icon4 {
  position: absolute;
  top: 17.7px;
  left: 86px;
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.in-available {
  height: 45px;
  width: 102px;
  position: relative;
  text-align: right;
  font-size: var(--font-size-11xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}
.frame-parent14 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
}
.frame-wrapper3,
.separator11 {
  align-self: stretch;
  box-sizing: border-box;
}
.frame-wrapper3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs) 0 var(--padding-11xs);
  max-width: 100%;
}
.separator11 {
  height: 1px;
  position: relative;
  border-top: 1px solid
    var(--www-montyesim-com-checkout-1920x1080-default-black-10);
}
.available-in4,
.validity-7-days1 {
  position: relative;
  line-height: 18px;
}
.available-in4 {
  line-height: 21px;
}
.trsvg-icon10 {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
}
.available-in-parent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: right;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--color-darkslategray-100);
}
.validity-7-days-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-6xs);
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-gray-300);
}
.supported-country4 {
  width: 117.1px;
  border: 0;
  outline: 0;
  font-family: var(--font-tajawal);
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  background-color: transparent;
  height: 21px;
  position: relative;
  line-height: 21px;
  color: var(--color-darkslategray-100);
  text-align: left;
  display: flex;
  align-items: center;
}
.divcol-md-5-icon4 {
  height: 24.6px;
  width: 199.4px;
  position: relative;
}
.divcard25,
.frame-parent13 {
  align-self: stretch;
  display: flex;
}
.divcard25 {
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-100);
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-mini) 0 var(--padding-mini) var(--padding-mini);
  gap: var(--gap-xl);
}
.frame-parent13 {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px 0;
  max-width: 100%;
}
.buy-now-4 {
  position: relative;
  line-height: 24px;
}
.button25 {
  width: 465px;
  border-radius: var(--br-31xl);
  background-color: var(--color-coral);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-6xs) var(--padding-4xs);
  box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}
.whatsapp-logo6d974d7esvg-icon7 {
  width: 280px;
  height: 178.8px;
  position: absolute;
  margin: 0 !important;
  right: -151px;
  bottom: -53.9px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
}
.divcard24 {
  width: 513px;
  border-radius: var(--br-xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-base) var(--padding-5xl)
    var(--padding-mid);
  box-sizing: border-box;
  position: relative;
  gap: 21px 0;
  max-width: 100%;
}
.europe47a0e084svg-icon1 {
  height: 55px;
  width: 55px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.euroturktrio1 {
  margin-top: -1px;
  position: relative;
  line-height: 24px;
}
.frame-container1 {
  height: 23px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
}
.pbundle-marketing-name {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-12xs);
}
.pbundle-marketing-name-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-10xs);
  box-sizing: border-box;
  min-width: 156px;
}
.europe47a0e084svg-group {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 10px;
  min-width: 198px;
}
.gb4 {
  position: relative;
  line-height: 45px;
}
.arrow-right2472f8ffsvg-icon5 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: -1px;
}
.frame-parent15,
.in-available1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.in-available1 {
  align-items: center;
  text-align: right;
  font-size: var(--font-size-11xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}
.frame-parent15 {
  flex: 1;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: 0 62px;
  max-width: 100%;
}
.divcard-child,
.separator12 {
  align-self: stretch;
  box-sizing: border-box;
}
.divcard-child {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs) var(--padding-9xs) var(--padding-11xs);
  max-width: 100%;
  flex-shrink: 0;
}
.separator12 {
  height: 1px;
  position: relative;
  border-top: 1px solid
    var(--www-montyesim-com-checkout-1920x1080-default-black-10);
}
.available-in5,
.validity-15-days {
  position: relative;
  line-height: 18px;
}
.available-in5 {
  line-height: 21px;
}
.trsvg-icon11 {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
  margin-left: -1px;
}
.available-in-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  text-align: right;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--color-darkslategray-100);
}
.div-card-button1,
.separator-group {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.div-card-button1 {
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.separator-group {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs);
  gap: 15px 0;
  flex-shrink: 0;
  font-size: var(--font-size-xs);
  color: var(--color-gray-300);
}
.supported-countries1 {
  width: 126.7px;
  border: 0;
  outline: 0;
  font-family: var(--font-tajawal);
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  background-color: transparent;
  height: 21px;
  position: relative;
  line-height: 21px;
  color: var(--color-darkslategray-100);
  text-align: left;
  display: flex;
  align-items: center;
}
.divcol-md-5-icon5 {
  height: 24.6px;
  width: 199.4px;
  position: relative;
}
.divcard27 {
  align-self: stretch;
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-mini) var(--padding-12xs) var(--padding-mini)
    var(--padding-mini);
  gap: var(--gap-xl);
  flex-shrink: 0;
}
.buy-now-5 {
  position: relative;
  line-height: 24px;
}
.button26,
.divcard26 {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
}
.button26 {
  width: 465px;
  border-radius: var(--br-31xl);
  background-color: var(--color-coral);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-5xs);
  flex-shrink: 0;
  white-space: nowrap;
  text-align: center;
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}
.divcard26 {
  width: 513px;
  border-radius: var(--br-xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-base) var(--padding-4xl)
    var(--padding-mid);
  gap: 16px 0;
}
.trsvg-icon12 {
  width: 55px;
  height: 55px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
}
.trsvg-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 0;
}
.mutluluk3 {
  margin-top: -1px;
  position: relative;
  line-height: 24px;
}
.mutluluk-container {
  height: 23px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
}
.pbundle-marketing-name-one2 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-12xs);
  box-sizing: border-box;
  min-width: 74px;
}
.gb5 {
  position: absolute;
  top: -0.3px;
  left: 0.1px;
  line-height: 45px;
  display: flex;
  align-items: center;
  width: 86.6px;
  height: 45px;
}
.arrow-right2472f8ffsvg-icon6 {
  position: absolute;
  top: 17.3px;
  left: 86.3px;
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.in-available2 {
  align-self: stretch;
  height: 45px;
  position: relative;
}
.frame-parent17,
.supported-days {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.supported-days {
  width: 102px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-2xs);
  text-align: right;
  font-size: var(--font-size-11xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}
.frame-parent17 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 12px;
  max-width: 100%;
}
.frame-wrapper4,
.separator13 {
  align-self: stretch;
  box-sizing: border-box;
}
.frame-wrapper4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 var(--padding-12xs);
  max-width: 100%;
}
.separator13 {
  height: 1px;
  position: relative;
  border-top: 1px solid
    var(--www-montyesim-com-checkout-1920x1080-default-black-10);
}
.available-in6,
.validity-30-days1 {
  position: relative;
  line-height: 18px;
}
.available-in6 {
  line-height: 21px;
}
.trsvg-icon13 {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
  margin-left: -1px;
}
.available-in-parent4 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  text-align: right;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--color-darkslategray-100);
}
.validity-30-days-group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-5xs) 0 var(--padding-6xs);
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-gray-300);
}
.supported-country5 {
  width: 117.1px;
  border: 0;
  outline: 0;
  font-family: var(--font-tajawal);
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  background-color: transparent;
  height: 21px;
  position: relative;
  line-height: 21px;
  color: var(--color-darkslategray-100);
  text-align: left;
  display: flex;
  align-items: center;
}
.divcol-md-5-icon6 {
  height: 24.6px;
  width: 199.4px;
  position: relative;
}
.divcard29,
.frame-parent16 {
  align-self: stretch;
  display: flex;
}
.divcard29 {
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-100);
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-mini) var(--padding-12xs) var(--padding-mini)
    var(--padding-mini);
  gap: var(--gap-xl);
}
.frame-parent16 {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px 0;
  max-width: 100%;
}
.buy-now-6 {
  position: relative;
  line-height: 24px;
}
.button27,
.divcard28 {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
}
.button27 {
  width: 465px;
  border-radius: var(--br-31xl);
  background-color: var(--color-coral);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-5xs);
  white-space: nowrap;
  text-align: center;
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}
.divcard28 {
  width: 513px;
  border-radius: var(--br-xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-base) var(--padding-6xl)
    var(--padding-mid);
  gap: 20px 0;
}
.trsvg-icon14 {
  width: 55px;
  height: 55px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
}
.trsvg-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 0;
}
.mutluluk-30-off {
  margin-top: -1px;
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.mutluluk-30-off-wrapper {
  height: 23px;
  width: 159px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
}
.pbundle-marketing-name-one3 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-12xs);
  box-sizing: border-box;
  min-width: 159px;
}
.gb6 {
  position: relative;
  line-height: 45px;
}
.arrow-right2472f8ffsvg-icon7 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.arrow-right2472f8ffsvg-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0 0;
}
.gb-container {
  flex-direction: row;
  align-items: center;
}
.frame-parent19,
.frame-wrapper6,
.gb-container {
  display: flex;
  justify-content: flex-start;
}
.frame-wrapper6 {
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) var(--padding-2xs) 0;
  text-align: right;
  font-size: var(--font-size-11xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}
.frame-parent19 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 0 6px;
  max-width: 100%;
}
.frame-wrapper5,
.separator14 {
  align-self: stretch;
  box-sizing: border-box;
}
.frame-wrapper5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs) 0 var(--padding-11xs);
  max-width: 100%;
}
.separator14 {
  height: 1px;
  position: relative;
  border-top: 1px solid
    var(--www-montyesim-com-checkout-1920x1080-default-black-10);
}
.available-in7,
.validity-30-days2 {
  position: relative;
  line-height: 18px;
}
.available-in7 {
  line-height: 21px;
}
.trsvg-icon15 {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: var(--br-71xl);
  overflow: hidden;
  flex-shrink: 0;
}
.available-in-parent5 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--color-darkslategray-100);
}
.validity-30-days-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-6xs);
  gap: var(--gap-xl);
  font-size: var(--font-size-xs);
  color: var(--color-gray-300);
}
.supported-country6 {
  width: 117.1px;
  border: 0;
  outline: 0;
  font-family: var(--font-tajawal);
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  background-color: transparent;
  height: 21px;
  position: relative;
  line-height: 21px;
  color: var(--color-darkslategray-100);
  text-align: left;
  display: flex;
  align-items: center;
}
.divcol-md-5-icon7 {
  height: 24.6px;
  width: 199.4px;
  position: relative;
}
.divcard31,
.frame-parent18 {
  align-self: stretch;
  display: flex;
}
.divcard31 {
  border-radius: var(--br-7xs);
  background-color: var(--color-whitesmoke-100);
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-mini) 0 var(--padding-mini) var(--padding-mini);
  gap: var(--gap-xl);
}
.frame-parent18 {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px 0;
  max-width: 100%;
}
.buy-now-7 {
  position: relative;
  line-height: 24px;
}
.button28 {
  width: 465px;
  border-radius: var(--br-31xl);
  background-color: var(--color-coral);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}
.button21,
.button28,
.divcard30 {
  display: flex;
  max-width: 100%;
}
.divcard30 {
  width: 513px;
  border-radius: var(--br-xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-base) var(--padding-6xl)
    var(--padding-mid);
  box-sizing: border-box;
  gap: 20px 0;
}
.button21 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 26px 24.95px;
  min-height: 598px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 900px) {
  .gb1,
  .gb2,
  .gb3,
  .gb4 {
    font-size: var(--font-size-5xl);
    line-height: 36px;
  }
  .frame-parent15 {
    gap: 0 62px;
  }
  .gb5,
  .gb6 {
    font-size: var(--font-size-5xl);
    line-height: 36px;
  }
}
@media screen and (max-width: 450px) {
  .gb1 {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .div-card-button,
  .divcard21,
  .frame-parent10 {
    flex-wrap: wrap;
  }
  .gb2 {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .divcard23,
  .frame-parent12,
  .validity-30-days-parent {
    flex-wrap: wrap;
  }
  .gb3 {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .frame-parent14,
  .validity-7-days-group {
    flex-wrap: wrap;
  }
  .divcard25 {
    padding-right: var(--padding-mini);
    box-sizing: border-box;
  }
  .divcard25,
  .europe47a0e084svg-group {
    flex-wrap: wrap;
  }
  .gb4 {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .div-card-button1,
  .divcard27,
  .frame-parent15 {
    flex-wrap: wrap;
  }
  .gb5 {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .divcard29,
  .frame-parent17,
  .validity-30-days-group {
    flex-wrap: wrap;
  }
  .gb6 {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .divcard31,
  .frame-parent19,
  .validity-30-days-container {
    flex-wrap: wrap;
  }
  .divcard31 {
    padding-right: var(--padding-mini);
    box-sizing: border-box;
  }
}
