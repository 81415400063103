.divfooter-section-icon7 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: var(--br-77xl);
}
.office4 {
  position: relative;
  line-height: 30px;
}
.headquarters-bridge-house4,
.queen-victoria-street5 {
  margin: 0;
}
.headquarters-bridge-house-container7 {
  width: 100%;
}
.headquarters-bridge-house-container6 {
  width: 341.2px;
  height: 48px;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
}
.divcol-md-47 {
  width: 563px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-mid);
  box-sizing: border-box;
  gap: 17px 0;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 450px) {
  .office4 {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}
