.monty-background-logo4bc5e4d4-icon {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jumpingmanb1022dbcpng-icon {
  height: 532px;
  width: 487px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  max-width: 100%;
  z-index: 1;
}
.monty-mobile,
.travele-s-i-m-section {
  position: absolute;
  display: flex;
  align-items: center;
}
.travele-s-i-m-section {
  top: 0;
  left: 0;
  width: 1139px;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-61xl)
   var(--padding-242xl) 
   var(--padding-59xl);
  box-sizing: border-box;
  max-width: 100%;
}
.monty-mobile {
  margin: 0;
  top: 102.5px;
  left: 975px;
  font-size: var(--font-size-7xl);
  line-height: 39px;
  font-weight: 700;
  font-family: inherit;
  width: 180.2px;
  height: 39px;
  z-index: 1;
}
.across-different-industries,
.company-offering-innovative,
.for-mobile-network,
.monty-mobile-is,
.solutions-we-provide {
  margin: 0;
}
.monty-mobile-is-container1 {
  width: 100%;
}
.monty-mobile-is-container {
  position: absolute;
  top: 157px;
  left: 975px;
  line-height: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 517px;
  height: 128.5px;
  z-index: 1;
}
.communication-globally-allowi,
.monetization-platforms-facili,
.optimal-customer-experience,
.our-revolutionary-omni,
.products,
.through-a-broad {
  margin: 0;
}
.our-revolutionary-omni-container1 {
  width: 100%;
}
.our-revolutionary-omni-container {
  position: absolute;
  top: 301px;
  left: 975px;
  line-height: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 511.7px;
  height: 152.5px;
  z-index: 1;
}
.a-key-regional,
.gathering-under-its,
.long-lasting-thirst-for,
.operators-and-service,
.with-its-innovative {
  margin: 0;
}
.with-its-innovative-container1 {
  width: 100%;
}
.with-its-innovative-container {
  position: absolute;
  top: 469px;
  left: 975px;
  line-height: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 520px;
  height: 120px;
  z-index: 1;
}
.divrow15 {
  align-self: stretch;
  height: 692px;
  position: relative;
  /* background-color: var(--color-burlywood); */
  background: linear-gradient(90deg, #ca3770, #5378ee );
  max-width: 100%;
}
.travel-esim {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 39px;
  font-weight: 700;
  font-family: inherit;
}
.consideration-each-trip,
.destination-they-arrive,
.one-of-the,
.so-they-either,
.which-is-not {
  margin: 0;
}
.one-of-the-container {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 300;
  color: var(--www-montyesim-com-checkout-1920x1080-default-bunting);
}
.monty-background {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0;
  max-width: 100%;
}
.but-now-monty,
.competitive-roaming-rates,
.download-its-app,
.himher-to,
.is-an-international,
.need-to-insert,
.network-according-to,
.process-is-done {
  margin: 0;
}
.but-now-monty-container {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 300;
  color: var(--www-montyesim-com-checkout-1920x1080-default-bunting);
}
.monty-background-parent,
.monty-mobile-info-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.monty-background-parent {
  gap: 48px 0;
}
.monty-mobile-info-frame {
  padding: 0 0 var(--padding-mid);
  box-sizing: border-box;
  min-width: 513px;
}
.ukwindow6f071d2bsvg-icon {
  height: 552px;
  width: 503px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  max-width: 100%;
}
.travel-e-s-i-m-section {
  width: 1263px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.travel-e-s-i-m-section-wrapper {
  width: 1380px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-7xl);
  color: var(--color-coral);
}
.monty-mobile-divider {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-81xl);
  box-sizing: border-box;
  gap: 100px 0;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 1500px) {
  .monty-mobile-is-container , .monty-mobile,
   .with-its-innovative-container ,.our-revolutionary-omni-container{

    left: 800px;
  }
}
@media screen and (max-width: 1400px) {
  .monty-mobile-divider {
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
  .travele-s-i-m-section {
    padding-left: 100px ;
  }
  .monty-mobile-is-container , .monty-mobile,
  .with-its-innovative-container ,.our-revolutionary-omni-container{

   left: 650px;
 }
}
@media screen and (max-width: 1200px) {
  
  .travel-e-s-i-m-section {
    flex-wrap: wrap;
  }
  .monty-mobile-divider {
    gap: 100px 0;
  }
  .monty-mobile-is-container , .monty-mobile,
  .with-its-innovative-container ,.our-revolutionary-omni-container{

   left: 500px;
 }
 .travele-s-i-m-section {
  padding-left: 0 ;
}
}
@media screen and (max-width: 1050px) {
  .jumpingmanb1022dbcpng-icon{
    height: 400px;
    width: 365px;
  }
  .monty-mobile-is-container , .monty-mobile,
  .with-its-innovative-container ,.our-revolutionary-omni-container{
   left: 400px;
   
 }
 .ukwindow6f071d2bsvg-icon{
  object-fit: contain;
}
}



@media screen and (max-width: 920px) {
  .divrow15{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 60px 0;
    height: 1200px;
    padding: 20px ;
    /* font-size: 12.5px; */
    

  }
  .monty-mobile{
    position: relative;
    top: 2%;
    left: 0;
    /* width: 100%; */
  }
  .monty-mobile-is-container{
    position: relative;
    top: 0px;
    left: 0;
    /* width: 100%; */
    height: 10%;
    /* font-weight: 600 ;
    line-height:20px; */
    
  }
  .our-revolutionary-omni-container{
    position: relative;
    top: 60px;
    left: 0;
    /* width: 100%; */
    height: 8%;
    /* font-weight: 600 ;
    line-height:20px; */
  }
  .with-its-innovative-container{
    position: relative;
    top: 130px;
    left: 0;
    /* width: 100%; */
    height: 10%;
    /* font-weight: 600 ;
    line-height:20px; */
  }
  .monty-background-logo4bc5e4d4-icon{
    display: none;
  }
  .travele-s-i-m-section{
    /* position: relative; */
    padding: 0;
    top: 700px;
    left: 25%;
    width: unset;
    max-width: unset;
  }
  
}
@media screen and (max-width: 550px) {
  .divrow15{
    font-size: 14px;
  }
  .monty-mobile{
    top: auto;
    /* left: -26%; */
    width: fit-content;
  }
  .monty-mobile-is-container{
    top: 0px;
    left: 3%;
    width: fit-content;
    font-weight: 600 ;
    line-height:20px;
    
  }
  .our-revolutionary-omni-container{
    top: 40px;
    left: 3%;
    width: fit-content;
    font-weight: 600 ;
    line-height:20px;
  }
  .with-its-innovative-container{
    top: 90px;
    left: 3%;
    width: fit-content;
    font-weight: 600 ;
    line-height:20px;
  }
  .travele-s-i-m-section{
    top: 800px;
    left: 10% ;
  }
  .jumpingmanb1022dbcpng-icon{
    height: 300px;
    width: 275px;
  }
  .monty-mobile-info-frame{
    min-width: 320px;
  }
}
