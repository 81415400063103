.navigation-link-esim20log {
  align-self: stretch;
  height: 40px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.navigation-link-esim20log-wrapper {
  width: 121px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 0;
  box-sizing: border-box;
}
.link-plans {
  font-weight: 600;
}
.link-how,
.link-partnership,
.link-plans {
  position: relative;
  line-height: 22.5px;
}
.link-how {
  font-weight: 600;
  white-space: nowrap;
}
.link-partnership {
  color: var(--color-burlywood);
}
.link-about,
.link-contact {
  position: relative;
  line-height: 22.5px;
  font-weight: 600;
  white-space: nowrap;
}
.link-plans-parent {
  height: 23px;
  width: 653px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: 0 68px;
  max-width: 100%;
}
.en {
  position: relative;
  line-height: 21px;
  text-transform: capitalize;
}
.drop-arrowed3f48dfsvg-icon {
  height: 21px;
  width: 10px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.navigation {
  align-self: stretch;
  border-radius: var(--br-81xl);
  background-color: var(
    --www-montyesim-com-checkout-1920x1080-default-athens-gray
  );
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-2xs) var(--padding-4xs)
    var(--padding-sm);
  gap: 0 3px;
}
.sign-in {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  text-align: center;
  white-space: nowrap;
}
.navigation-link {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-9xl);
  background-color: var(--color-burlywood);
  border-radius: var(--br-6xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.navigation-link:hover {
  background-color: var(--color-darksalmon);
}
.frame-parent,
.navigation-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.navigation-parent {
  width: 208px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 38px;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}
.frame-parent {
  width: 1606px;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-2xl) 0 0;
  gap: var(--gap-xl);
  max-width: 100%;
}
.message-textarea,
.wwwmontyesimcom-by-htmltod-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.wwwmontyesimcom-by-htmltod-inner {
  width: 1780px;
  height: 127px;
  flex-direction: row;
  padding: 0 0 var(--padding-68xl) var(--padding-xl);
  text-align: center;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-semibold-15-size
  );
  color: var(--www-montyesim-com-checkout-1920x1080-default-black);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
.message-textarea {
  width: 1763px;
  flex-direction: column;
  padding: 0 0 var(--padding-29xl) var(--padding-xl);
  gap: 90px 0;
}
.link-contact1,
.link-privacy,
.link-terms {
  position: relative;
  line-height: 21px;
  font-weight: 300;
  flex-shrink: 0;
}
.link-contact1 {
  flex: 1;
  display: inline-block;
  min-width: 60px;
  max-width: 100%;
}
.email-link {
  width: 1004px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: 0 38px;
  max-width: 100%;
}
.monty-esim {
  position: relative;
  line-height: 21px;
  font-weight: 300;
  text-align: right;
}
.divfooter-sub {
  align-self: stretch;
  background-color: var(--color-coral);
  border-top: 1px solid var(--www-montyesim-com-checkout-1920x1080-default-nero);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-23xl) var(--padding-206xl) var(--padding-23xl)
    var(--padding-293xl);
  gap: var(--gap-xl);
  max-width: 100%;
  z-index: 2;
}
.wwwmontyesimcom-by-htmltod1 {
  width: 100%;
  position: relative;
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /* padding: var(--padding-6xl) 0 0; */
  /* gap: 65px 0; */
  box-sizing: border-box;
  letter-spacing: normal;
  text-align: center;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size
  );
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 1700px) {
  .divfooter-sub {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1325px) {
  .link-plans-parent {
    display: none;
  }
  .frame-parent {
    width: 953px;
  }
  .message-textarea {
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .email-link {
    flex-wrap: wrap;
  }
  .divfooter-sub {
    padding-left: var(--padding-137xl);
    padding-right: var(--padding-93xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .link-plans-parent {
    gap: 0 68px;
  }
  .message-textarea {
    gap: 90px 0;
  }
  .email-link {
    gap: 0 38px;
  }
  .divfooter-sub {
    padding-left: var(--padding-59xl);
    padding-right: var(--padding-37xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .link-plans-parent {
    gap: 0 68px;
  }
  .message-textarea {
    gap: 90px 0;
  }
}
