.divcss-1d8n9bt {
  position: absolute;
  top: calc(50% - 18px);
  left: 5px;
  width: 32px;
  height: 36px;
  overflow: hidden;
}

.divcountry,
.divcss-1s2u09g-control {
  border-radius: var(--br-11xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
}

.divcss-1s2u09g-control {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  height: 100%;
  overflow: hidden;
}

.divcountry {
  width: 54px;
  height: 54px;
  position: relative;
  display: none;
}

.navigation-link-esim20log4 {
  align-self: stretch;
  height: 40px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.text-frame {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-smi) 0 0;
  box-sizing: border-box;
}

.link-plans4 {
  position: relative;
  line-height: 22.5px;
  color: var(--color-burlywood);
}

.link-how4 {
  white-space: nowrap;
}

.link-about4,
.link-contact7,
.link-how4,
.link-partnership4 {
  position: relative;
  line-height: 22.5px;
  font-weight: 600;
}

.link-about4,
.link-contact7 {
  white-space: nowrap;
}

.frame-with-links {
  height: 23px;
  width: 651px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: 0 67px;
  max-width: 100%;
}

.en4 {
  position: relative;
  line-height: 21px;
  text-transform: capitalize;
}

.drop-arrowed3f48dfsvg-icon4 {
  height: 21px;
  width: 10px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.navigation5 {
  align-self: stretch;
  border-radius: var(--br-81xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-athens-gray);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) var(--padding-2xs) var(--padding-4xs) var(--padding-sm);
  gap: 0 3px;
}

.sign-in3 {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--www-montyesim-com-checkout-1920x1080-default-poppins-light-17);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  text-align: center;
  white-space: nowrap;
}

.navigation-link3 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs) var(--padding-9xl);
  background-color: var(--color-burlywood);
  border-radius: var(--br-6xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.navigation-link3:hover {
  background-color: var(--color-darksalmon);
}

.dropdown-frame,
.f-r-a-m-e-o-n-e,
.link-frame {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.dropdown-frame {
  align-self: stretch;
  width: 208px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: 0 38px;
  font-size: var(--www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
}

.f-r-a-m-e-o-n-e,
.link-frame {
  max-width: 100%;
}

.f-r-a-m-e-o-n-e {
  align-self: stretch;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-11xs) 0 0;
  gap: var(--gap-xl);
}

.link-frame {
  width: 1600px;
  height: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 0;
  text-align: center;
  font-size: var(--www-montyesim-com-checkout-1920x1080-default-poppins-semibold-15-size);
  color: var(--www-montyesim-com-checkout-1920x1080-default-black);
  font-family: var(--www-montyesim-com-checkout-1920x1080-default-poppins-light-17);
}

.heading-12 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 48px;
  font-weight: 700;
  font-family: inherit;
}

.row-frame-with-cards {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-14xl) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

.please {
  font-weight: 300;
}

.dial-06,
.please,
.to-check-device {
  position: relative;
  line-height: 30px;
}

.dial-06 {
  color: var(--color-coral);
}

.to-check-device {
  font-weight: 300;
}

.your-device {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-9xs);
}

.your-device-is {
  position: relative;
  line-height: 30px;
  font-weight: 300;
}

.check-device-comp,
.footer-frame {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.check-device-comp {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 234px;
}

.footer-frame {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  row-gap: 20px;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
}

.popular-countries-frame {
  width: 508px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 7px 0;
  max-width: 100%;
}

.searchicon5548a86csvg {
  height: 33px;
  width: 34px;
  border-radius: var(--br-31xl);
  overflow: hidden;
  flex-shrink: 0;
  min-height: 53px;
  z-index: 1;
  margin: 0 3%;
  cursor: pointer;
}

.searchicon-active .input {
  width: 100%;
  height: 100%;
  border: 0;
  /* background: transparent; */
  background: #faf9f9;
  padding: 2px;
  border-radius: 25px;
  font-size: 1rem;
  color: #2c2c2c;
  outline: none;
}

.searchicon .input {
  display: none;
}

.searchicon-active {
  position: absolute;
  width: 100%;
  box-shadow: 2px 3px 5px 3px #0000006e;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: #faf9f9;
  border-radius: 25px;
  height: 100%;
  z-index: 9;
  transition: all 0.3s ease;

}

.searchicon {
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  background: #faf9f9;
  border-radius: 25px;
  width: 7%;
  height: 100%;
  z-index: 0;
  transition: all 0.3s ease;
}

.divglider-plans {
  height: 41px;
  width: 140px;
  position: relative;
  border-radius: var(--br-8xl);
  background-color: var(--color-coral);
  display: none;

}

.countries {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--www-montyesim-com-checkout-1920x1080-default-poppins-light-17);
  /* color: var(--www-montyesim-com-checkout-1920x1080-default-nero); */
  text-align: center;
  z-index: 2;
}

/* .button,
.t-r-s-v-g {
  /* display: flex;
  flex-direction: row;
  align-items: center; 
} */

.t-r-s-v-g {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini);
  /* background-color: var(--color-coral); */
  background: linear-gradient(90deg, #ca3770, #5378ee );
  border-radius: var(--br-8xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  z-index: 1;
  flex: 0.8432;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: background-color 0.3s ease;
}

/* .t-r-s-v-g:hover {
  background-color: var(--color-chocolate);
} */

.button {
  flex: 1;
  border-radius: var(--br-80xl);
  border: 0;
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  justify-content: flex-end;
  padding: var(--padding-6xs) var(--padding-12xs) var(--padding-7xs) var(--padding-6xs);
  box-sizing: border-box;
  min-width: 111px;
}

.regions {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--www-montyesim-com-checkout-1920x1080-default-poppins-light-17);
  /* color: var(--www-montyesim-com-checkout-1920x1080-default-black); */
  text-align: center;
}

.button1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  flex: 0.8432;
  border-radius: var(--br-80xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 111px;
}

/* .button1:hover,
.button2:hover {
  background-color: var(--color-gainsboro);
} */

.global {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--www-montyesim-com-checkout-1920x1080-default-poppins-light-17);
  /* color: var(--www-montyesim-com-checkout-1920x1080-default-black); */
  text-align: center;
}

.button2,
.divtabs-plans {
  border-radius: var(--br-80xl);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  display: flex;
}

.button2 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-mini);
  flex: 0.8432;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 111px;
}

.divtabs-plans {
  flex: 1;
  box-shadow: 0 0 25.53px rgba(42, 106, 152, 0.1);
  justify-content: flex-start;
  min-width: 289px;
  row-gap: 20px;
  max-width: 100%;
}

.buttons-frame,
.divtabs-plans,
.nav-link-frame {
  flex-direction: row;
  align-items: flex-start;
}

.buttons-frame {
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0 11px;
  max-width: 100%;
}

.nav-link-frame {
  align-self: stretch;
  padding: 0 var(--padding-15xl) 0 0;
  box-sizing: border-box;
}

.button-group-frame,
.country-region-frame,
.nav-link-frame {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.country-region-frame {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 33px 0;
}

.button-group-frame {
  width: 588px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-5xl) var(--padding-7xl) var(--padding-xl);
  box-sizing: border-box;
  margin-bottom: 2%;
}

.search-frame,
.wwwmontyesimcom-by-htmltod5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.search-frame {
  align-self: stretch;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-coral);
  font-family: var(--www-montyesim-com-checkout-1920x1080-default-poppins-light-17);
}

.wwwmontyesimcom-by-htmltod5 {
  width: 100%;
  position: relative;
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  /* padding: var(--padding-6xl) 0 0; */
  box-sizing: border-box;
  gap: 105px 0;
  letter-spacing: normal;
}



@media screen and (max-width: 500px) {

  /* .frame-with-links {
    gap: 0 67px;
  } */
  .popular-countries-frame {
    width: 308px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    gap: 0 0;
  }

  .footer-frame {
    gap: 0;
  }

  .check-device-comp {
    align-items: center;
  }

  .buttons-frame {
    display: flex;
    flex-direction: column;
  }

  .searchicon {
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0;
    background: #faf9f9;
    color: #2c2c2c;
    z-index: 9;
    box-shadow: 0px 1px 2px 0px #00000042;
  
  }

  .divtabs-plans {
    order: 1;
    justify-content: flex-start;
    width: 100%;
    margin: 10px 0;
  }

  .searchicon .input {
    display: block;
    background-color: transparent;
    font-size: medium;
    width: 100%;
    z-index: 100;
  }
  #plans-supported-country {
    gap: 40px;
    font-size: 14px;
  }
 #plans-button6 {
    padding-left: var(--padding-59xl);
    padding-right: 69px;
  }

}