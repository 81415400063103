.heading-18 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 62px;
  font-weight: 700;
  font-family: inherit;
}
.we-look-forward,
.your-business-with {
  margin: 0;
}
.we-look-forward-container {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 30px;
  font-weight: 300;
  color: var(--color-slategray-200);
  display: inline-block;
  max-width: 100%;
}
.benefits-frames2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px 0;
  max-width: 100%;
}
.easy-iconf11bd8cdsvg {
  height: 48px;
  width: 47px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.access-to-discounted,
.esim-data-bundle {
  margin: 0;
}
.access-to-discounted-container {
  position: relative;
  line-height: 19.84px;
  font-weight: 300;
}
.easy-iconf11bd8cdsvg-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 23px;
}
.money-icone283b833svg {
  height: 48px;
  width: 47px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.connected-while-traveling,
.customers-a-convenient,
.effective-solution-for,
.opportunity-to-offer {
  margin: 0;
}
.opportunity-to-offer-container {
  position: relative;
  line-height: 19.84px;
  font-weight: 300;
}
.money-icon,
.money-icone283b833svg-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 23px;
}
.money-icon {
  flex-direction: column;
  gap: 23px 0;
}
.travel-icon1eb8c12dsvg {
  height: 48px;
  width: 47px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.dedicated-support-from,
.of-experts {
  margin: 0;
}
.dedicated-support-from-container {
  position: relative;
  line-height: 19.84px;
  font-weight: 300;
}
.benefits-frames1,
.discount-frame,
.travel-icon1eb8c12dsvg-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.travel-icon1eb8c12dsvg-parent {
  flex-direction: row;
  gap: 0 23px;
}
.benefits-frames1,
.discount-frame {
  flex-direction: column;
}
.discount-frame {
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: 32px 0;
  font-size: var(--font-size-base);
  color: var(--color-slategray-200);
}
.benefits-frames1 {
  gap: 48px 0;
  min-width: 602px;
}
.esim-beach3299f522svg-icon {
  align-self: stretch;
  height: 411px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.link-plans7 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 471px;
  max-width: 100%;
}
.whatsapp-logo6d974d7esvg-icon2 {
  height: 178.8px;
  width: 280px;
  position: fixed;
    right: -1%;
    bottom: -1%;
    z-index: 10;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  animation: jump 2.5s infinite linear;
  cursor: pointer;
}
@keyframes jump {
  0% {
    transform: translateY(20px);
  }

  40% {
    transform: translateY(0px);
  }

  80% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.frame-partnership {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0 26px;
  max-width: 100%;
}
.benefits-frames,
.copyright-notice,
.first-frame {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.copyright-notice {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-31xl) 0 0;
  box-sizing: border-box;
  min-width: 478px;
}
.benefits-frames,
.first-frame {
  flex-direction: row;
}
.benefits-frames {
  width: 1488px;
  justify-content: center;
  gap: 0 150px;
}
.first-frame {
  align-self: stretch;
  justify-content: flex-end;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-coral);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 1325px) {
  .benefits-frames1 {
    flex: 1;
  }
  .benefits-frames {
    flex-wrap: wrap;
    gap: 0 150px;
  }
}
@media screen and (max-width: 900px) {
  .heading-18 {
    font-size: var(--font-size-13xl);
    line-height: 50px;
  }
  .benefits-frames1 {
    gap: 48px 0;
    min-width: 100%;
  }
  .link-plans7 {
    min-height: auto;
  }
  .whatsapp-logo6d974d7esvg-icon2 {
    flex: 1;
  }
  .frame-partnership {
    flex-wrap: wrap;
  }
  .copyright-notice {
    padding-top: var(--padding-13xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .benefits-frames {
    gap: 0 150px;
  }
}
@media screen and (max-width: 500px) {
  .heading-18 {
    font-size: var(--font-size-5xl);
    line-height: 37px;
  }

  .discount-frame {
    gap: 32px 0;
  }
  .benefits-frames {
    gap: 0 150px;
  }
  .we-look-forward-container {
    font-size: 15px;
    line-height: 22px;
  }
  .whatsapp-logo6d974d7esvg-icon2{
    height: 150px;
    width: 250px;
  }
}
