.phoneicon0eb21fd3svg1 {
  width: 18px;
  height: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.phoneicon0eb21fd3svg,
.phoneicon0eb21fd3svg-fill {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.phoneicon0eb21fd3svg {
  align-items: flex-start;
  justify-content: flex-start;
}
.divfooter-section-icon16,
.divrow18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.divfooter-section-icon16 {
  border: 0;
  padding: var(--padding-mini);
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  height: 48px;
  width: 48px;
  border-radius: var(--br-77xl);
  box-sizing: border-box;
}
.divrow18 {
  align-self: stretch;
  flex-wrap: wrap;
  padding: 0 var(--padding-xl) var(--padding-xl);
}
.phone5 {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 30px;
  font-weight: 300;
  font-family: inherit;
  display: inline-block;
  max-width: 533.3300170898438px;
}
.brand-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-mini);
}
.link-445 {
  position: relative;
  font-size: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17-size
  );
  line-height: 25.5px;
  font-weight: 300;
  display: inline-block;
  max-width: 533.3300170898438px;
  white-space: nowrap;
}
.divcol-md-416 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-20xl);
  box-sizing: border-box;
  min-width: 335px;
  max-width: 563px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 825px) {
  .divcol-md-416,
  .link-445,
  .phone5 {
    max-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .phone5 {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}
