.divcol-md-4-parent,
.footer-sub {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.footer-sub {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
  margin-top: -178px;
}
.divcol-md-4-parent {
  width: 1127px;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 1127px;
}
.divfooter4 {
  align-self: stretch;
  background: linear-gradient(
   
    #ca3770 ,
    #5378ee 
  );
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: var(--padding-21xl) 0;
  box-sizing: border-box;
  gap: 0 1px;
  min-height: 288px;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 1700px) {
  .divcol-md-4-parent {
    flex: 1;
  }
  .divfooter4 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1325px) {
  .divcol-md-4-parent {
    min-width: 100%;
  }
}
