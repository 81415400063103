
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: linear-gradient(90deg, #ca3770, #5378ee );
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.popular-countries {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 36px;
  font-weight: 700;
  font-family: inherit;
}
.popular-countries-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xl);
}
.whatsapp-logo6d974d7esvg-icon6 {
  height: 178.8px;
  width: 280px;
  position: fixed;
  right: -1%;
  bottom: -1%;
  z-index: 9;
  animation: jump 2.5s infinite linear;
  cursor: pointer;
  transition: all 0.3s;
}
@keyframes jump {
  0% {
    transform: translateY(20px);
  }

  40% {
    transform: translateY(0px);
  }

  80% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.divrow16 {
  width: 1638px;
   display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
  /* grid-auto-flow: row dense; */
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  position: relative;
  gap: 24px 25.95px;
  min-height: 484px;
  max-width: 100%;
}
.view-all-countries1 {
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 27px;
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  text-align: center;
}
.button19 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-base) var(--padding-sm) var(--padding-base)
    var(--padding-mini);
  /* background-color: var(--color-burlywood); */
  background: linear-gradient(90deg, #ca3770, #5378ee );
  width: 222px;
  border-radius: var(--br-23xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
}
.button19:hover {
  background: linear-gradient(90deg, #9e2d5a, #354e9e );
}
.country-flags1,
.div-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.div-row {
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
  align-self: stretch;
  align-items: center;
  padding: var(--padding-13xl) 0 0;
  gap: 48px 0;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
}
.country-flags1 {
  width: 1700px;
  align-items: flex-start;
  padding: 0 var(--padding-xl) var(--padding-29xl);
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-shark1);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 900px) {
  .div-row {
    gap: 48px 0;
  }
  .country-flags1 {
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 500px) {
  .divcard17 {
    width: 345px
  }
  /* .country-flags1 {
    width: 1000px;
  }
  .divrow16 {
    width: 300px;
  } */
  .whatsapp-logo6d974d7esvg-icon6 {
      height: 100px;
      width: 150px;
      right: -2%;
      margin-bottom: 5%;
  }
  
  .popular-countries {
    font-size: var(--font-size-lgi);
    line-height: 29px;
  }
}
