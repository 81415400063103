.divfooter-section-icon5 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: var(--br-77xl);
}
.office2 {
  position: relative;
  line-height: 30px;
  font-weight: 300;
}
.headquarters-bridge-house2,
.queen-victoria-street2 {
  margin: 0;
}
.headquarters-bridge-house-container3 {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 300;
}
.divcol-md-45,
.office-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.office-parent {
  gap: 15px 0;
  flex-shrink: 0;
}
.divcol-md-45 {
  width: 583.3px;
  padding: 0 0 var(--padding-mid) var(--padding-xl);
  box-sizing: border-box;
  gap: 20px 0;
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  font-family: var(
    --www-montyesim-com-checkout-1920x1080-default-poppins-light-17
  );
}
@media screen and (max-width: 450px) {
  .office2 {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
}
