.wwwmontyesimcom-by-htmltod {
  width: 100%;
  position: relative;
  background-color: var(--www-montyesim-com-checkout-1920x1080-default-nero);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 100px 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1325px) {
  .wwwmontyesimcom-by-htmltod {
    gap: 100px 0;
  }
}
@media screen and (max-width: 900px) {
  .wwwmontyesimcom-by-htmltod {
    gap: 100px 0;
  }
}
