@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
}
:root {
  /* fonts */
  --www-montyesim-com-checkout-1920x1080-default-poppins-light-17: Poppins;
  --font-tajawal: Tajawal;

  /* font sizes */
  --www-montyesim-com-checkout-1920x1080-default-poppins-medium-14-size: 14px;
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --www-montyesim-com-checkout-1920x1080-default-poppins-light-17-size: 17px;
  --font-size-lg: 18px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --www-montyesim-com-checkout-1920x1080-default-poppins-semibold-15-size: 15px;
  --font-size-2xs-1: 10.1px;
  --font-size-7xl: 26px;
  --font-size-2xl: 21px;
  --font-size-49xl: 68px;
  --font-size-35xl: 54px;
  --font-size-22xl: 41px;
  --font-size-xs: 12px;
  --font-size-11xl: 30px;
  --font-size-51xl: 70px;
  --font-size-37xl: 56px;
  --font-size-23xl: 42px;
  --www-montyesim-com-checkout-1920x1080-default-poppins-regular-176-size: 17.6px;

  /* Colors */
  --www-montyesim-com-checkout-1920x1080-default-nero: #fff;
  --color-coral: #ca3770;
  --www-montyesim-com-checkout-1920x1080-default-alice-blue: #f5fbfe;
  --color-burlywood: #ffbe98;
  --color-darksalmon: #e6a680;
  --color-darkslategray-100: #475467;
  --color-darkslategray-200: #344155;
  --www-montyesim-com-checkout-1920x1080-default-oxford-blue: #344054;
  --color-slategray-100: #717d8f;
  --color-slategray-200: #5e6282;
  --color-gray-100: #808080;
  --color-gray-200: #817a7a;
  --www-montyesim-com-checkout-1920x1080-default-boulder: #757575;
  --www-montyesim-com-checkout-1920x1080-default-bunting1: #132644;
  --color-gray-300: #122644;
  --www-montyesim-com-checkout-1920x1080-default-shark1: #212529;
  --www-montyesim-com-checkout-1920x1080-default-bunting: #14183e;
  --www-montyesim-com-checkout-1920x1080-default-shark: #19191c;
  --color-gray-400: #18191a;
  --color-gray-500: #101828;
  --www-montyesim-com-checkout-1920x1080-default-black-10: rgba(0, 0, 0, 0.1);
  --color-gainsboro: #e6e6e6;
  --www-montyesim-com-checkout-1920x1080-default-black: #000;
  --color-chocolate: #cc4700;
  --www-montyesim-com-checkout-1920x1080-default-athens-gray: #f8f9fa;
  --color-whitesmoke-100: #f2f4f7;
  --color-crimson: #ef2045;
  --color-lightgray: #ccc;
  --www-montyesim-com-checkout-1920x1080-default-scorpion: #5a5a5a;
  --color-mistyrose: #e2bfb3;

  /* Gaps */
  --gap-81xl: 100px;
  --gap-xl: 20px;
  --gap-0: 0px;
  --gap-mid: 17px;
  --gap-lg: 18px;
  --gap-base: 16px;
  --gap-47xl: 66px;
  --gap-5xs: 8px;
  --gap-16xl: 35px;
  --gap-41xl: 60px;
  --gap-21xl: 40px;
  --gap-mini: 15px;
  --gap-71xl: 90px;
  --gap-30xl: 49px;
  --gap-4xs: 9px;
  --gap-7xs: 6px;
  --gap-3xl: 22px;
  --gap-29xl: 48px;
  --gap-13xl: 32px;
  --gap-4xl: 23px;
  --gap-6xs: 7px;
  --gap-19xl: 38px;
  --gap-lgi: 19px;
  --gap-83xl: 102px;
  --gap-51xl: 70px;
  --gap-36xl: 55px;
  --gap-178xl: 197px;
  --gap-45xl: 64px;
  --gap-9xl: 28px;
  --gap-11xl: 30px;
  --gap-6xl: 25px;
  --gap-86xl: 105px;
  --gap-5xl: 24px;
  --gap-14xl: 33px;
  --gap-7xl: 26px;
  --gap-2xl: 21px;
  --gap-131xl: 150px;
  --gap-28xl: 47px;
  --gap-10xs: 3px;
  --gap-9xs: 4px;
  --gap-66xl: 85px;

  /* Paddings */
  --padding-6xl: 25px;
  --padding-12xs: 1px;
  --padding-23xl: 42px;
  --padding-206xl: 225px;
  --padding-22xl: 41px;
  --padding-286xl: 305px;
  --padding-57xl: 76px;
  --padding-37xl: 56px;
  --padding-133xl: 152px;
  --padding-93xl: 112px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-mid: 17px;
  --padding-19xl: 38px;
  --padding-2xl: 21px;
  --padding-183xl: 202px;
  --padding-29xl: 48px;
  --padding-66xl: 85px;
  --padding-112xl: 131px;
  --padding-12xl: 31px;
  --padding-base: 16px;
  --padding-lg: 18px;
  --padding-8xl: 27px;
  --padding-20xl: 39px;
  --padding-3xs: 10px;
  --padding-14xl: 33px;
  --padding-mini: 15px;
  --padding-33xl: 52px;
  --padding-3xl: 22px;
  --padding-15xl: 34px;
  --padding-72xl: 91px;
  --padding-67xl: 86px;
  --padding-40xl: 59px;
  --padding-24xl: 43px;
  --padding-sm: 14px;
  --padding-86xl: 105px;
  --padding-7xl: 26px;
  --padding-4xl: 23px;
  --padding-5xs: 8px;
  --padding-9xl: 28px;
  --padding-xs: 12px;
  --padding-30xl: 49px;
  --padding-11xl: 30px;
  --padding-6xs: 7px;
  --padding-9xs: 4px;
  --padding-7xs: 6px;
  --padding-35xl: 54px;
  --padding-32xl: 51px;
  --padding-13xl: 32px;
  --padding-4xs: 9px;
  --padding-2xs: 11px;
  --padding-smi: 13px;
  --padding-11xs: 2px;
  --padding-293xl: 312px;
  --padding-59xl: 78px;
  --padding-137xl: 156px;
  --padding-10xs: 3px;
  --padding-8xs: 5px;
  --padding-31xl: 50px;
  --padding-68xl: 87px;
  --padding-5xl: 24px;
  --padding-207xl: 226px;
  --padding-94xl: 113px;
  --padding-17xl: 36px;
  --padding-81xl: 100px;
  --padding-46xl: 65px;
  --padding-61xl: 80px;
  --padding-242xl: 261px;
  --padding-48xl: 67px;
  --padding-25xl: 44px;
  --padding-10xl: 29px;
  --padding-102xl: 121px;
  --padding-60xl: 79px;
  --padding-45xl: 64px;
  --padding-98xl: 117px;
  --padding-101xl: 120px;
  --padding-39xl: 58px;
  --padding-51xl: 70px;
  --padding-292xl: 311px;
  --padding-58xl: 77px;
  --padding-136xl: 155px;
  --padding-34xl: 53px;
  --padding-lgi: 19px;
  --padding-16xl: 35px;
  --padding-139xl: 158px;
  --padding-84xl: 103px;
  --padding-99xl: 118px;
  --padding-448xl: 467px;
  --padding-97xl: 116px;
  --padding-214xl: 233px;
  --padding-154xl: 173px;
  --padding-121xl: 140px;
  --padding-12xs-5: 0.5px;
  --padding-18xl: 37px;
  --padding-12xs-8: 0.8px;

  /* Border radiuses */
  --br-77xl: 96px;
  --br-111xl: 130px;
  --br-11xl: 30px;
  --br-xl: 20px;
  --br-6xl: 25px;
  --br-80xl: 99px;
  --br-8xl: 27px;
  --br-81xl: 100px;
  --br-3xs: 10px;
  --br-11xs: 2px;
  --br-23xl: 42px;
  --br-71xl: 90px;
  --br-31xl: 50px;
  --br-7xs: 6px;
  --br-11xs-6: 1.6px;
}
